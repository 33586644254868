export const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
}

export const getCelsiusFromFahrenheit = (value) => {
    return Math.round((value - 32) / 1.8);
}

// based on: https://www.joezimjs.com/javascript/3-ways-to-parse-a-query-string-in-a-url/
export function parseQueryString(queryString) {
    const tempQueryString = decodeURIComponent(queryString).substring(1); // skip ? character
    const params = {};
    const queries = tempQueryString.split('&'); // Split into key/value pairs

    // Convert the array of strings into an object
    queries.forEach((query) => {
        const temp = query.split('=');
        params[temp[0]] = temp[1];
    });

    return params;
}
