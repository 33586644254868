import React from 'react';
import { useTranslation } from 'react-i18next';
import { SvgIcon } from './svgIcon';

export default function ButtonGridPopup(props) {
  const [selectedTitleKey, setSelectedTitleKey] = React.useState('');
  const { t } = useTranslation();
  
  const handleClick = (event,param) => {
    
    
    
    window.open(param, '_blank', 'noopener,noreferrer')
    if (selectedTitleKey) {
      // user has already pressed a button, no more click is allowed
      return;
    }

    // safeguard
    if (!event.currentTarget.dataset || !event.currentTarget.dataset.titlekey) {
      return;
    }
    
    const clonedTitleKey = event.currentTarget.dataset.titlekey;
    setSelectedTitleKey(clonedTitleKey);

  }

  const getButtonClass = (titleKey) => {
    return selectedTitleKey && selectedTitleKey === titleKey ?
      'o-link-button animate__button-grid' : 'o-link-button';
  }


  

  return (
    <div className='c-button-grid' data-testid='button-grid'>
      {props.links && props.links.map(link => {
        const imageName = link.svgName || 'tile-arrow';
        const colorStyle = link.colorStyle || props.buttonColorStyle;

        return (
          <div
            className={`${colorStyle} ${getButtonClass(link.titleKey)}`}
            data-titlekey={link.titleKey}
            onClick={event => handleClick(event, link.address)}
            role='button'
            key={link.titleKey}
          >
            <h1 className='l-padding__left30 l-padding__right30 l-margin__top16 t-semibold__40'>
              {t(link.titleKey)}
            </h1>
            <SvgIcon
              className='l-padding__left30 l-padding__bottom30'
              name={imageName}
              fill={link.svgFill}
            />
          </div>
        );
      })}
    </div>
  );
}
