import { HttpService } from './httpService';
import { weatherIconMapping } from '../models/weatherMapping';
import { getCelsiusFromFahrenheit } from '../utils/utils';

const parsedWeatherData = (data) => {
    const parsedData = {
        temperatureStr: '',
        weatherIcon: '',
    }

    if (data && data.properties && data.properties.periods) {
        const current = data.properties.periods.find(item => item.number === 1);

        // parse for temperature
        if (current && !isNaN(parseInt(current.temperature))) {
            const celsius = getCelsiusFromFahrenheit(current.temperature);
            parsedData.temperatureStr = `${current.temperature}°F/${celsius}°C`;
        }

        // parse for weather icon
        if (current && current.icon) {
            const segments = current.icon.split('/');
            if (segments.length > 0) {
                const lastPath = segments[segments.length - 1];
                // strip out any trailing comma or question mark from the last segment and map to UI icon
                const apiIconName = lastPath.split(',')[0].split('?')[0];
                const mappedItem = weatherIconMapping.find(item => item.group.includes(apiIconName));
                if (mappedItem) {
                    parsedData.weatherIcon = mappedItem.svgIcon;
                }
            }
        }
    }

    return parsedData;
}

export const WeatherService = {
    getNewYorkWeather() {
        const weatherUrl = 'https://api.weather.gov/gridpoints/OKX/36,38/forecast';
        const requestOptions = {
            method: 'GET',
        };

        return new Promise((resolve, reject) => {
            HttpService(weatherUrl, requestOptions)
                .then(data => {
                    resolve(parsedWeatherData(data));
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
}
