import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SvgIcon } from './svgIcon';

export default function NavigationControls(props) {
  const { t } = useTranslation();
  const history = useHistory();

  const onBackPressed = () => {
    history.goBack();
  };

  const onFeedbackPressed = () => {
    history.push({
      pathname: '/external',
      search: '?id=feedback',
    });
  };

  return (
    <div className='c-navigation-controls-container'>
      <div className='c-navigation-controls'>
        {props.hideBackButton &&
          <span className='color-white t-semibold__26'>
            {t('letUsKnowHow')}
          </span>
        }
        {!props.hideBackButton &&
          <div className='o-back-button' onClick={onBackPressed}>
            <SvgIcon
              className='o-small-back-arrow'
              height={'80px'}
              name='arrow-white-left'
            />
            <span className='l-margin__left17 color-white t-semibold__40'>
              {t('back')}
            </span>
          </div>
        }
        <div className='o-feedback-button' onClick={onFeedbackPressed}>
          <span className='color-black t-semibold__16'>
            {t('provideFeedback')}
          </span>
          <SvgIcon
            className='o-small-feedback-arrow'
            height={'16px'}
            name='arrow-black-right'
          />
        </div>
      </div>
    </div>
  );
}
