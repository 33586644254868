import React from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import wholeNewLGAlogo from '../../assets/images/new_whole_log_logo.png';

export default function NewAirportTile() {
  const history = useHistory();

  const route = {
    pathname: '/airport',
    search: '?id=newLGAAirport',
  }

  const handleClick = (event) => {
    event.preventDefault();
    history.push(route);
  }

  return (
    <div role='button' onClick={handleClick} className='o-airport-link-button l-position__relative c-service-item-link'>
      <img
        className='o-new-airport-banner-logo'
        src={wholeNewLGAlogo}
        alt={'A whole new LGA'}
      />
      <span
        className='o-new-airport-banner-legend'
      ><Trans i18nKey='learnMore' /></span>
    </div>
  );
}
