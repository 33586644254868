import React from 'react';
import { Trans } from 'react-i18next';

export const ServiceItem = (props) => {
  const { name, phone, link, singleItem } = props;

  return (
    <div className='c-service-item-flex'>
      <div className='c-service-item-name'>
        <a href={link} target='_blank' rel='noopener noreferrer' className='c-service-item-link'><Trans i18nKey={name} /></a>
      </div>
      <div className={singleItem ? 'c-service-item-phone-single-item' : 'c-service-item-phone'}>{phone}</div>
    </div>
  );
}
