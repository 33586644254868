import React from "react";
import ButtonGrid from '../components/buttonGrid';
import { COLORS } from '../constants/appConstants';

export const Information = () => (
  <ButtonGrid
    links={[
      {
        titleKey: 'flightTracker',
        to: {
          pathname: '/information/external',
          search: '?id=flightTracker',
        },
        svgName: 'tile-flight-tracker',
        svgFill: COLORS.BLACK,
      },
      {
        titleKey: 'lostAndFound',
        to: '/information/lost-found',
        svgName: 'tile-lost-and-found',
        svgFill: COLORS.BLACK,
      },
      {
        titleKey: 'airlineDirectory',
        to: {
          pathname: '/information/external',
          search: '?id=airlineDirectory',
        },
        svgName: 'tile-airline-directory',
        svgFill: COLORS.BLACK,
      },
      {
        titleKey: 'airportMap',
        to: {
          pathname: '/information/external',
          search: '?id=airportMap',
        },
        svgName: 'tile-airport-map',
        svgFill: COLORS.BLACK,
      },
      {
        titleKey: 'parking',
        to: {
          pathname: '/information/external',
          search: '?id=parking',
        },
        svgName: 'tile-parking',
        svgFill: COLORS.BLACK,
      },
      {
        titleKey: 'shopsAndRestaurants',
        to: {
          pathname: '/information/external',
          search: '?id=shopsAndRestaurants',
        },
        svgName: 'tile-shops-and-restaurants',
        svgFill: COLORS.BLACK,
      },
    ]}
    buttonColorStyle='background-color-yellow'
  />
);
