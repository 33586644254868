// weather data pull rate
export const WEATHER_DATA_UPDATE_INTERVAL_MS = 300000; // 5 mins
export const TIME_DATA_UPDATE_INTERVAL_MS = 10000; // 10 secs

export const APP_TIME_ZONE = "America/New_York";

// notes: expose SCSS colors in codes, they should match the values in color.scss
export const COLORS = {
    WHITE: '#FFFFFF',
    BLACK: '#000000',
    GREEN: '#2CC84D',
    RED: '#EE2737',
    ORANGE: '#F7561B',
    SKY_BLUE: '#00A5DF',
    YELLOW: '#FFCD00',
};
