import React from "react";
import { useTranslation } from 'react-i18next';
import backdropImage from '../assets/images/lost-found-backdrop.png';
import { SvgIcon } from '../components/svgIcon';
import { Destinations } from '../utils/destinations';

export const LostFound = () => {
  const { t } = useTranslation();
  const urlItem = Destinations.find(item => item.id === 'lostAndFound');
  const link = urlItem ? urlItem.url : '/';

  return (
    <React.Fragment>
      <img
        src={backdropImage}
        alt={t('lostAndFound')}
        className="o-lost-found-backdrop"
      />
      <div className="c-lost-found-container l-flex__column-align-start">
        <div className="t-semibold__40">{t('lostAndFound')}</div>
        <div className="t-base__20-30 l-margin__top20">{t('lostFoundLine1')}</div>
        <div className="t-base__20-30 l-margin__top20">{t('lostFoundLine2')}</div>
        <div className="l-margin__top30">
          <a href={link} target='_blank' rel='noopener noreferrer'>
            <div className='o-feedback-button'>
              <span className='color-black t-semibold__16'>
                {t('reportLostItem')}
              </span>
              <SvgIcon
                className='o-small-feedback-arrow'
                height={'16px'}
                name='arrow-black-right'
              />
            </div>
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}
