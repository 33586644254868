import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { EllipsisLoader } from '../components/ellipsisLoader';
import { Destinations } from '../utils/destinations';
import { parseQueryString } from '../utils/utils';

export const ExternalPage = () => {
  const [isInit, setIsInit] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  let params = {};
  let url = '';
  if (location.search) {
    params = parseQueryString(location.search);
  }
  if (params.id) {
    const destination = Destinations.find(item => item.id === params.id);
    if (destination) {
      url = destination.url;
    }
  }

  // safeguard
  if (!url) {
    history.replace('/');
    return null;
  }

  const handleOnLoad = () => {
    // only trigger animation on initial load
    if (!isInit) {
      setIsInit(true);
    }
  }

  const getIframeClass = () => {
    return isInit ? 'c-external-iframe animate__fade-in' : 'c-external-iframe o-opacity__0';
  }

  return (
    <div className="l-position__relative">
      <iframe
        className={getIframeClass()}
        title='externalSite'
        src={url}
        onLoad={handleOnLoad}
        sandbox='allow-forms allow-scripts allow-same-origin allow-forms allow-popups allow-popups-to-escape-sandbox'
      />
      {!isInit && (
        <div className="c-iframe__loader l-flex__middle">
          <EllipsisLoader />
        </div>
      )}
    </div>
  );
}
