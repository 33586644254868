import React from 'react';
import ButtonGrid from '../../components/buttonGridPopup';

export default function Visit() {
  return (
    <div>
      <ButtonGrid
        links={[
          {
            address: 'https://www.iloveny.com/places-to-go/',
            titleKey: 'placesToGo',
            to: {
              pathname: '/visit/external',
              search: '?id=placesToGo1',
            },
            svgName: 'tile-places-to-go',
          },
          {
            address: 'https://www.iloveny.com/things-to-do/',
            titleKey: 'thingsToDo',
            to: {
              pathname: '/visit/external',
              search: '?id=thingsToDo',
            },
            svgName: 'tile-things-to-do',
          },
          {
            address: 'https://www.iloveny.com/events/',
            titleKey: 'events',
            to: {
              pathname: '/visit/external',
              search: '?id=events',
            },
            svgName: 'tile-events',
          },
          {
            address: 'https://www.iloveny.com/travel-tools/',
            titleKey: 'planYourTrip',
            to: {
              pathname: '/visit/external',
              search: '?id=planYourTrip',
            },
            svgName: 'tile-plan-your-trip',
          },
        ]}
        buttonColorStyle='background-color-sky-blue'
      />
    </div>
  );
}
