import React from 'react';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { ServiceItem } from '../components/serviceItem';
import imageBanner from '../assets/images/car-service.png';
import airportMap from '../assets/images/lga-airport-map.png';
import airportLegends from '../assets/images/airport-legends.png';
import { shareRideServices } from '../assets/objects/shareRideServicesObjects';
import { expressBusServices } from '../assets/objects/expressBusServicesObjects';
import { privateCarServices } from '../assets/objects/privateCarServicesObjects';

const RentalCarService = () => {

  return (
    <React.Fragment>
      <div className='p-page'>
        <img
          className='p-page-header-image'
          src={imageBanner}
          alt={'Car service'}
        />
        <div className='p-page-content'>
          <h1 className='p-page-header'><Trans i18nKey='carServices' /></h1>
          <div className='p-page-description'><Trans i18nKey='carServicesDescription' /></div>
          <h2 className='p-page-subheader'><Trans i18nKey='sharedRideServices' /></h2>
          <div className='p-page-description'><Trans i18nKey='carServicesSharedRideServicesDescription' >Reservation for a Share Ride van service can be made using the self-service phones or <a href={'https://lga.gtisnext.com/kiosk'} rel="noopener noreferrer" target='_blank' className='t-semibold__20'>online here.</a></Trans></div>
          <div className='p-page-flex-box l-margin_top35'>
            {shareRideServices.map((shareRideService) =>
              <ServiceItem key={shareRideService.name} name={shareRideService.name} phone={shareRideService.phone} link={shareRideService.link} />)}
          </div>
          <h2 className='p-page-subheader'><Trans i18nKey='expressBusServices' /></h2>
          <div className='p-page-description'><Trans i18nKey='carServicesExpressBusServicesDescription' /></div>
          <div className='p-page-flex-box l-margin_top35'>
            {expressBusServices.map((expressBusService) =>
              <ServiceItem key={expressBusService.name} name={expressBusService.name} phone={expressBusService.phone} link={expressBusService.link} singleItem/>)}
          </div>

          <h1 className='p-page-header l-margin_top95'><Trans i18nKey='carServicesTerminalBShareRide' /></h1>
          <div className='p-page-description'><Trans i18nKey='carServicesTerminalBShareRideDescription' /></div>
          <div className='p-page-description l-margin_top35'><Trans i18nKey='carServicesTerminalBShareRideServicesDescription' /></div>
          <h2 className='p-page-subheader'><Trans i18nKey='privateCarServices' /></h2>
          <div className='p-page-description'><Trans i18nKey='privateCarServicesDescription' /></div>
          <div className='p-page-flex-box l-margin_top35'>
            {privateCarServices.map((privateCarService) =>
              <ServiceItem key={privateCarService.name} name={privateCarService.name} phone={privateCarService.phone} link={privateCarService.link} singleItem/>)}
          </div>

          <h1 className='p-page-header l-margin_top95'><Trans i18nKey='carServicesTerminalBPrivateRide' /></h1>
          <div className='p-page-description'><Trans i18nKey='carServicesTerminalBPrivateRideDescription' /></div>
          <h1 className='p-page-header l-margin_top95'><Trans i18nKey='airportMap' /></h1>
          <img
            className='p-image'
            src={airportMap}
            alt={'Airport Map'}
          />
          <img
            className='p-image p-airport-legend'
            src={airportLegends}
            alt={'Airport Legends'}
          />

        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(RentalCarService);
